
.ql-editor::before {
    font-style: normal !important;
    color: #999; 
  }
  
  .ql-container {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    min-height: 12rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .ql-toolbar.ql-snow{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }